.gallery-container-mobile {
    padding-top: 40px;
    display: flexbox;
    flex-direction: row;
    position: absolute;
    left: 1%;
    right: 1%;
}

.gallery-container {
    display: flexbox;
    flex-direction: row;
    position: absolute;
    left: 1%;
    right: 1%;
}
.image-container {
    border-radius: 10%;
    border: 10px;
}


.toolbar-top-mobile {
    padding-top: 5%    ;
    background-color: antiquewhite;
    padding: 1%;
}

.toolbar-top {
    background-color: antiquewhite;
    padding: 1%;
}

.toolbar-bottom {
    padding: 0.5%;
    display: flex;
    justify-content: center;


}

.toolbar-bottom-button {
    padding: 10%;
}

.page {
    align-self: center;
    padding-left: 2%;
    padding-right: 2%;
}

.overlay {
    z-index: 99999; 
  }
  
.rs-picker-select-menu {
    z-index: 1000; 
  }