.logo-mobile {
  width: 75px;
  height: 60px;
  position: fixed;
  top: auto;
  left: 45%;



  
}

.spacer-burger-menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100px;
}

.burger-menu-parent {
  position: fixed;
  top: 0;
  left: 0;
  height: 20px;
  width: 100%;
  padding-bottom: 45px;




  z-index: 100; /* Ensure it stays on top of other content */
  background-color: #faebd7; 
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3); 
  border-radius: 3px;
}


/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 20px;
  top: 20px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #faebd7;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #faebd7;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #3c3c3c;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  font-family: "Dancing Script";
  color: black;
  border-radius: 5px; 
  background-color: lightgray;
  border-color: #373a47;
  margin-top: 3%;
  display: flex;
  flex-direction: row;
  align-self: center;
  text-align: center;
  text-justify: center;
  justify-items: center;
  padding-left: 2%;
  padding-top: 10%;
  padding-right: 2%;
  font-size: x-large;
}

/* Styling of overlay */
.bm-overlay {
  background: rgb(123, 123, 0, 0.3);
}