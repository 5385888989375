@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap");
/* base styles */
* {
  margin: 0;
  font-family: "Quicksand";
  color: #333;
}



.navbar {
  padding: 20px;
  display: flex;
  align-items: center;
  max-width: 600px;
  margin: 0 auto;
  border-bottom: 1px solid #f2f2f2;
  border-radius: 50%;
}
.navbar h1 {
  color: #f1356d;
}
.navbar .links {
  margin-left: auto;
}
.navbar a {
  margin-left: 16px;
  text-decoration: none;
  padding: 6px;
}
.navbar a:hover {
  color: #f1356d;
}
.content {
  max-width: 600px;
  margin: 40px auto;
}
:root {
  --accent-color-1: #faebd7;
  --navbar-font-color: black;
  --font-title-color: black; 
  /*--title-font: "Tangerine"*/;

  .navbar-list{
    display: flex;
    flex-direction: row;
    justify-content: self-end;
  }



  .navbar-item {
    font-family: "Dancing Script";
    font-weight: bolder;
    font-size: x-large;
    color: #333;
  }

  .logo {
    padding-left: 2vh;
    margin-top: -2.5vh;
    margin-bottom: -3vh;
    max-height: 8vh;
    left: 0;
    position: absolute;
  }
  
  /* set the title color in the home jumbotron*/
  
  
 

}
