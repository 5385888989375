@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400..700&display=swap');


.container {
    background-color: aqua;
}

.container-parent-mobile{
    padding: 1%;
    padding-left: 3%;
    padding-right: 3%;
}

.container-mobile{
    background-color: #faebd7;
    border-radius: 4px;
    padding-left: 3%;
    padding-right: 3%;
    margin-bottom: 3%;
    padding-bottom: 3%;

    
}
.spacer {
  padding-top: 30px;
  display: flex;
  flex-direction: row;
}

.home-title {
    color: var(--font-title-color);
    display: flexbox;
    align-self: center;
    justify-self: center;
    flex-direction: row;
    padding-bottom: 1%;
    font-family: var(--title-font), bold;
    font-weight: 700;
    font-size: x-large;
    font-style: normal;
    font-size: 5vh;
  }
  
  .home-text {
    color: var(--font-title-color);
    display: flexbox;
    flex-direction: row;
    padding-top: 3%;
    padding-left: 10%;
    font-family: var(--title-font);
    font-weight: 400;
    font-style: normal;
    font-size: 4vh;
  }



  .start {
    background-color: black;
    display: flexbox;
    flex-direction: column;
  }

  .start-mobile {
    padding-top: 40px;
    display: flex;
    flex-direction: column;
  }

  .section-mobile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
  }
  
  .home-info-mobile{
    background-color: #faebd7;
    border-radius: 8px;
    padding: 3%;
    margin: 3%;
  }

  .container {
    background-color: aqua;
}

.container-parent-mobile{
    padding: 1%;
    padding-left: 3%;
    padding-right: 3%;
}

.container-mobile{
    font-family: "Dancing Script";
    background-color: #faebd7;
    border-radius: 4px;
    padding-left: 3%;
    padding-right: 3%;
    margin-bottom: 3%;
    padding-bottom: 3%;

    
}

.contact {
    background-color: #fef7ee;
    border-style:groove;
    padding: 3%;
    padding-top: 5%;
    padding-bottom: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-bottom: 1%;

}

.spacer {
    padding-top: 30px;
    display: flex;
    flex-direction: row;
}

.copyright {
    display: flex;
    flex-direction: row;
    background-color: #faebd7;
    border-radius: 5px;
    justify-content: center;
    align-content: center;
}

.taste-container{
    display: flex;
    justify-content: space-between;
    padding: 1%;
}

.taste-child{
  flex: 1;
  font-family: "Dancing Script";
  padding: 1%;
  margin: 3%;
  overflow: hidden;
  
}