.container {
    background-color: aqua;
}

.container-parent-mobile{
    padding: 1%;
    padding-left: 3%;
    padding-right: 3%;
}

.container-mobile{
    background-color: #faebd7;
    border-radius: 4px;
    padding-left: 3%;
    padding-right: 3%;
    margin-bottom: 3%;
    padding-bottom: 3%;

    
}

.contact {
    background-color: #fef7ee;
    border-style:groove;
    padding: 3%;
    padding-top: 5%;
    padding-bottom: 5%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    margin-bottom: 1%;

}

.spacer {
    padding-top: 30px;
    display: flex;
    flex-direction: row;
}

.copyright {
    display: flex;
    flex-direction: row;
    background-color: #faebd7;
    border-radius: 5px;
    justify-content: center;
    align-content: center;
}