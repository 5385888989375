.row-parent {
    background-color: #faebd7;
    display: flex;
    flex-direction: row;
    margin-top: 0.5%;
    padding-right: 2%;
    border-radius: 10px;
    max-height: 12%;
    align-items: center;
    border-style: dashed;
    border-color: grey;
}

.thumbnail {

    max-width: 9vh;
    border-radius: 4px;
}

.delete-button {

    position: absolute;
    right: 1%;

}

.button {

    height: auto;
    width: 100%;
}

.toolbar {
    display: flex;
    flex-direction: row;
    justify-content: right;

    background-color: red;   
}

