.manager {
    position: absolute;
    left: 1%;
    right: 1%;
    div > *:not(:last-child) {
        margin: 1vh;
    } 
}

.gallerymanager-spinner-parent{
    padding-top: 35vh;
    flex-direction: column;
    display: flex;
    align-content: center;
    justify-content: center;
}


.gallerymanager-spinner {

    align-self: center;
}

.add-image-div {
    background-color: antiquewhite;
    padding: 1%;
    margin: 1%;
    border-radius: 5px;
}

.error-message {
    color:red;
}

.toolbar {
    margin: 1%;
    padding: 1%;
    background-color: grey;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    justify-content: flex-end;
}

.toolbar-mobile {
    margin: 1%;
    padding: 1%;
    background-color: grey;
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 5px;
    justify-content: flex-end;
}

.image-row{
    margin: 1%;
    padding: 1%;
    display: flex;
    justify-content: space-between;
    background-color: antiquewhite;
    outline: 2px double #363535;
    outline-offset: 1px;
    border-radius: 5px;
}

.image-row-left {
    display: flex; 
    flex-direction: row;
    padding-left: 2%;
    align-items: center;
    justify-content: start;
    align-items: center;

}

image-row-right {
    display: flex;
    flex-direction: row;
    align-items: center;

}

.row-thumbnail {
    width:  50px;
    height: 50px;
    border-radius: 3px;
}

.spacer {
    padding-top: 35px;
    display: flex;
    flex-direction: row;
  }