

.container {
    background-color: aqua;
}

.container-parent-mobile{
    padding: 1%;
    padding-left: 3%;
    padding-right: 3%;
}

.container-mobile{
    background-color: #faebd7;
    border-radius: 4px;
    padding-left: 3%;
    padding-right: 3%;
    margin-bottom: 3%;
    padding-bottom: 3%;

    
}

.home-title {
    padding-left: 2%;
    padding-right: 2%;
    padding-bottom: 4%;
    font: "Dancing Script";
    text-align: center;
  }
  


.home-jumbotron {
  padding-left: 2%;
  flex-direction: row;
  justify-self: flex-start;
  position: absolute;
  left: 1%; 
  right: 1%;
  border-radius: 1%;
  box-shadow: 10px 20px 20px grey;

}

.jumbotron-picture {
  max-height: 40vh;
  max-width: 40vh;
  float: left;
  padding: 3%;
  border-radius: 11%;
}

.instagram-embed{
  display: flexbox;
  justify-content: center;

}
.instagram-embed-parent {
  padding-top: 10%;
  padding-left: 5%;
  padding-right: 5%;
  display: inline;
  flex-direction: row;
  position: absolute;
  justify-content: center;
  left: 0;
  right: 0;
}
.start {
  background-color: black;
  flex-direction: column;
}

.start-mobile {
  padding-top: 40px;

}

.section-mobile {
  font-family: 'Dancing Script';
  flex-direction: row;
  padding-top: 2%;
  padding-left: 2%;
  padding-right: 2%;
  margin: 1%;

}

.home-info-mobile{
  background-color: #faebd7;
  border-radius: 8px;
  padding: 1%;
  margin: 1%;
}


.home-text{
  padding-left: 1%;
  padding-right: 1%;
}